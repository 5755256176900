
import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import i18n from "i18next";
import {
  HeaderSection,
  LogoContainer,
  CustomNavLinkSmall,
  Span,
  Burger,
  NotHidden,
  Menu,
  Label,
  Outline,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("关于")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("产品")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("client")}>
          <Span>{t("客户")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("ragomics-footer")}
        >
          <Button>{t("联系")}</Button>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => handleChange("en")}>
          <Span>{t("EN")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => handleChange("cn")}>
          <Span>{t("CN")}</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container >
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage" onClick={(e) => {
            e.preventDefault(); // Prevent default link behavior
            window.scrollTo(0, 0); // Scroll to the top of the page
            window.location.reload(); // Force reload the page
          }}>
            <SvgIcon src="logo.png" width="180px" height="70px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col>
            <Label onClick={onClose}>
              <Col>
                <Menu>{t("菜单")}</Menu>
              </Col>
              <Col>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
