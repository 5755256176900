import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 0.3vw 0vw 0.3vw 0vw;
  background: rgba(245, 245, 245, 0.9);
  border-radius: 1rem;
  position: sticky; 
  top: 0; 
  box-shadow: 0px 2px 5px 5px rgb(241, 242, 243);
  width: 100%;
  z-index: 1000;
  .ant-row-space-between {
    align-items: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
    font-size: 1.2rem;
    color: #18216d;
    transition: color 0.2s ease-in;

    @media only screen and (max-width: 10000px) {
      margin: 1.25rem 1.5rem 1.25rem 1.25rem;
    }
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) underline;
  }
`;

export const LanguageSwitch = styled("div")<any>`
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;


export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;
